import { Route } from 'vue-router'
import router from '/~/router'

interface Action {
  type: string
  value: string
  params?: Record<string, any>
}

interface MenuItem {
  action: Action
  children?: MenuItem[]
  divider?: boolean
}

function checkMenuChildren(
  currentRoute: Route,
  item: MenuItem
): MenuItem | undefined {
  const { children = [] } = item

  for (const child of children) {
    const { action } = child
    const childItem = checkMenuChildren(currentRoute, child)

    if (childItem) {
      return childItem
    } else if (action && action.type === 'page' && action.value) {
      for (const route of currentRoute.matched) {
        if (route.name && String(route.name).indexOf(action.value) >= 0) {
          return item
        }
      }
    }
  }
}

function checkRoutes(currentRoute: Route, menu: MenuItem[]): boolean {
  const matchedNames = currentRoute.matched.map((i) => i.name as string)
  const menuNames = menu.map((i) => i.action.value).join(' ')

  for (const item of matchedNames) {
    const current = menuNames.match(new RegExp(item, 'g'))

    if (current && current.length > 1) return false
  }
  return true
}

export function getMenuActiveItem(
  currentRoute: Route,
  menu: MenuItem[]
): MenuItem | null {
  const isOne = checkRoutes(currentRoute, menu)

  for (const item of menu) {
    if (item.divider) {
      continue
    }

    const name = item.action.value

    for (const route of currentRoute.matched) {
      if (route.name === name && isOne) {
        return item
      }

      if (
        route.name === name &&
        !isOne &&
        JSON.stringify(item.action.params) ===
          JSON.stringify(currentRoute.params)
      ) {
        return item
      }
    }

    if (checkMenuChildren(currentRoute, item)) {
      return item
    } else {
      const { resolved } = router.resolve({ name })
      const resolvedFullPath = resolved.fullPath.slice(
        0,
        resolved.fullPath.length - 1
      )

      if (
        currentRoute.fullPath.indexOf(resolvedFullPath) === 0 &&
        resolvedFullPath.length > 0 &&
        isOne
      ) {
        return item
      }
    }
  }

  const { resolved: currentResolved } = router.resolve({
    name: currentRoute.name as string | undefined,
  })
  const { resolved: currentHighlightResolved } = router.resolve({
    name: currentRoute.meta?.menuHighlight,
  })

  const currentMatched = [
    ...currentResolved.matched.map((i) => i.path),
    ...currentHighlightResolved.matched.map((i) => i.path),
  ].filter(Boolean)
  const intersections: { value: number; item: MenuItem }[] = []

  for (const item of menu) {
    if (item.divider) {
      continue
    }

    const { resolved: menuItemResolved } = router.resolve({
      name: item.action.value,
    })
    const menuItemMatched = menuItemResolved.matched
      .map((m) => m.path)
      .filter(Boolean)

    if (menuItemMatched.includes(currentRoute.fullPath)) {
      return item
    } else {
      intersections.push({
        value: menuItemMatched.filter((x) => currentMatched.includes(x)).length,
        item,
      })
    }
  }

  if (intersections.length > 0) {
    const sortedIntersections = intersections.sort((a, b) => b.value - a.value)

    return sortedIntersections[0]?.item ?? null
  }

  return null
}
